<template>
  <div :class="$style.wrapper">
    <mark-few-days></mark-few-days>
  </div>
</template>

<script>
import MarkFewDays from '@/components/reports/analysis_calls/Efficiency_Mark/components/MarkFewDays.vue';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      selected: '1',
      options: [
        { text: 'Отчет о статистике звонков пользователей', value: '1' },
        { text: 'Отчет о звонках пользователей за день', value: '2' }
      ]
    };
  },

  components: {
    MarkFewDays
  },

  methods: {
    ...mapActions({ getCalls: 'callsAnalysis/getCalls' }),

    async showTable(params) {
      const { query, url } = params;

      await this.getCalls({ query, url, vm: this });
    }
  }
};
</script>

<style module>
.wrapper {
  padding: 1rem 1rem 0 1rem;
}
</style>

<template>
  <b-form @submit.stop.prevent="sendQuery">
    <b-row>
      <b-col v-for="date in dates" :key="date.label" sm="4" md="3" lg="4" xl="3">
        <b-form-group :label="date.label">
          <BaseInputDate v-model="date.value" @input="date.action()"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-for="item in inputs" :key="item.label">
      <b-col sm="7" md="5" lg="6" xl="4">
        <b-form-group :label="item.label">
          <BaseTextInput v-model="item.value" :placeholder="item.placeholder"/>
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <BaseButton>
      <template #text>
        Показать
      </template>
    </BaseButton>
  </b-form>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import {
  ShowCurrentDate,
  SetDateIntervalEnd,
  SetDateIntervalStart
} from '@/additional_helpers/dates';
import URL from '@/basUrl';
import { mapActions } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';

export default {
  data() {
    return {
      inputs: [
        { label: 'Номер линии:', value: '', placeholder: 'Введите номер линии' },
        {
          label: 'Продолжительность звонка от (сек):',
          value: '0',
          placeholder: 'Введите число не менее 0'
        }
      ],
      dates: [
        {
          value: ShowCurrentDate(),
          label: 'Начало интервала:',
          action: this.changeDateIntervalEnd
        },
        {
          value: ShowCurrentDate(),
          label: 'Конец интервала:',
          action: this.changeDateIntervalStart
        }
      ]
    };
  },

  components: {
    BaseButton,
    BaseTextInput,
    BaseInputDate
  },

  methods: {
    ...mapActions({ getCalls: 'callsAnalysis/getCalls' }),

    changeDateIntervalEnd() {
      this.dates[1].value = SetDateIntervalEnd(this.dates[0].value, this.dates[1].value);
    },

    changeDateIntervalStart() {
      this.dates[0].value = SetDateIntervalStart(this.dates[0].value, this.dates[1].value);
    },

    sendQuery() {
      const [intervalStart, intervalEnd] = this.dates;
      const [line, duration] = this.inputs;
      if (!duration.value) duration.value = 0;

      const query = `start=${intervalStart.value}&end=${intervalEnd.value}&line=${line.value}&duration=${duration.value}`;
      const url = URL.EFFICIENCY_DAYS;

      this.getCalls({ query, url, vm: this });
    }
  }
};
</script>

<style module></style>
